<template>
  <div>
    <b-modal
      id="modal-change-password"
      ok-variant="dark"
      modal-class="modal-dark"  
      ref="modal-change-password"
      centered
      title="Alterar senha"
    >
      <b-card-text>
        <validation-observer ref="simpleRules">
          <p>Você está preste a alterar a senha desse usuário</p>
          <b-row>
            <b-col>
              <b-form-group
                label-for="account-new-password"
                label="Nova senha"
              >
                <validation-provider
                  #default="{ errors }"
                  name="senha"
                  vid="Password"
                  rules="required"
                >
                  <b-form-input
                    id="account-new-password"
                    type="password"
                    name="new-password"
                    placeholder="Nova senha"
                    v-model="dataPasswords.password"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-for="account-retype-new-password"
                label="Confirme sua nova senha"
              >
              <validation-provider
                  #default="{ errors }"
                  name="confirmação da senha"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    type="password"
                    name="retype-password"
                    placeholder="Confirme sua nova senha"
                    v-model="dataPasswords.password_confirmation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-dark"
            block
            @click="updatePassword"
          >
            Alterar a senha
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataPasswords: {
        password: null,
        password_confirmation: null,
        user_id: null
      }
    };
  },
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
  },
  methods: {
    updatePassword(paginated = false, page = 1){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$store.dispatch("auth/newPasswordByAdmin", this.dataPasswords)
          .then((response) => { 
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Senha atualizado com sucesso!',
                icon: 'LockIcon',
                text: 'Operação executada com sucesso.',
                variant: 'info'
              }
            })
            this.dataPasswords.user_id = null,
            this.dataPasswords.password = null,
            this.dataPasswords.password_confirmation = null
            this.$nextTick(() => this.$refs.simpleRules.reset());
          })
          .finally(() => {
            this.isLoading = false
            this.$bvModal.hide('modal-change-password')
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },
  },
  created() {
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
          this.dataPasswords.user_id = this.dataProp.id;
      }
    }
  },
};
</script>
<style lang="scss">
  .form-content{
    width: 5rem;

  }
  .form-content-search{
    width: 11rem;
  }
</style>